import React, { Component } from 'react'
import { connect } from 'react-redux'
import { downloadLocations, getLocations, startEmbedding } from '../actions/locationAction';
import { DropDownStyle, pageSizeOptions, states } from '../helpers'
import ReactPaginate from 'react-paginate';
import jwtSimple from "jwt-simple";
import {
    Table, TableHead, TableBody, TableHeaderElement, TableDataElement, TableRow, TableContainer, PageContainer, SubHeader, FilterContainer, Capitalize, StyledLabel, PaginationContainer, InputContainer, PageSizeContainer, StyledInput, Icon, ArrowButton, ButtonContainer, NoDataFound, PageCountContainer
} from '../components';
import { withRouter } from '../components/WithRouter';
import Spinner from '../components/Spinner';
import Select from 'react-select';
import Vector from '../assets/images/vector.svg';
import { toast } from 'react-toastify';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DownloadIcon from '../assets/images/download.svg';

class Location extends Component {
    state = {
        practiceId: '',
        userId: '',
        allLocations: [],
        pageNumber: 0,
        pageSize: 15,
        pageCount: 0,
        totalCount: 0,
        sortBy: 'lowerLocationName',
        order: 'asc',
        locName: '',
        inComplete: false,
        isLibraryStepUpdated: undefined,
        copied: false
    };

    componentDidMount = () => {
        const { dispatch, practice_id } = this.props;
        if (practice_id) {
            this.setState({ practiceId: practice_id });
            dispatch(getLocations({ ...this.state, practiceId: practice_id }));
        } else {
            const params = new URLSearchParams(window.location.search);
            const inComplete = params.get('inComplete') === 'true' ? true : false;
            this.setState({ inComplete });
            let practiceId = params.get('practiceId') || '';
            if (practiceId) {
                const { REACT_APP_JWT_SECRET } = process.env;
                practiceId = jwtSimple.decode(practiceId, REACT_APP_JWT_SECRET);
                this.setState({ practiceId });
            };
            let userId = params.get('userId') || '';
            if (userId) {
                const { REACT_APP_JWT_SECRET } = process.env;
                userId = jwtSimple.decode(userId, REACT_APP_JWT_SECRET);
                this.setState({ userId });
            };
            dispatch(getLocations({ ...this.state, practiceId, userId, inComplete }));
        }
    };

    componentWillReceiveProps = (updatedProps) => {
        const { pageSize } = this.state;
        const { locations, locationsCount } = updatedProps;
        const pageCount = Math.ceil(locationsCount / pageSize);
        this.setState({
            allLocations: locations,
            totalCount: locationsCount,
            pageCount: pageCount
        });
    };

    handlePageClick = async (event) => {
        const { dispatch } = this.props;
        const { pageSize } = this.state;
        this.setState({
            pageNumber: event.selected + 1
        });
        await dispatch(getLocations({ ...this.state, pageNumber: event.selected + 1, pageSize: pageSize }));
    };

    handleFilterInputChange = async (value) => {
        this.setState({
            pageNumber: 1,
            pageSize: 15,
            locName: value
        });
    };

    getFilteredData = async (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        await dispatch(getLocations({ ...this.state }));
    };

    resetFilter = async () => {
        const { dispatch } = this.props;
        this.setState({
            pageNumber: 0,
            pageSize: 15,
            sortBy: 'lowerLocationName',
            order: 'asc',
            locName: '',
            userId: '',
            practiceId: '',
            inComplete: false
        });
        dispatch(getLocations({ pageNumber: 0, pageSize: 15, }));
    };

    handlePageSizeInputChanges = async (value) => {
        const { dispatch } = this.props;
        this.setState({
            pageSize: value,
            pageNumber: 1
        });
        await dispatch(getLocations({ ...this.state, pageNumber: 1, pageSize: value }));
    };

    handleSortByAndOrderChange = async (sortBy, order) => {
        const { dispatch } = this.props;
        this.setState({
            sortBy,
            order,
            pageNumber: 1,
            pageSize: 15
        });
        await dispatch(getLocations({ ...this.state, pageNumber: 1, pageSize: 15, sortBy, order }));
    };

    goTo = async (url, id) => {
        const { REACT_APP_JWT_SECRET } = process.env;
        const { navigate } = this.props;
        navigate(url + '?locationId=' + jwtSimple.encode(id, REACT_APP_JWT_SECRET));
    };

    startEmbeddingForLocation = async (e, locationId) => {
        const { dispatch } = this.props;
        const { allLocations } = this.state;
        const tempLocation = allLocations.map((location) => {
            if (location._id === locationId) location.embeddingStatus = 'In Progress';
            return location;
        });
        this.setState({ allLocations: tempLocation });
        const result = await dispatch(startEmbedding(locationId));
        if (result) {
            toast.success('Embedding Successful.');
        };
        await this.getFilteredData(e);
    };

    downloadData = async () => {
        try {
            const { dispatch } = this.props;
            await dispatch(downloadLocations({ ...this.state }));
        } catch (error) {
            console.log("🚀 ~ Location ~ downloadData= ~ error:", error);
        }
    };

    copyLocationId = (id) => {
        try {
            navigator.clipboard.writeText(id);
            this.setState({ copied: true });

        } catch (error) {
            console.log("🚀 ~ Location ~ error:", error)
        }
    };
    handleMouseLeave = () => {
        setTimeout(() => { this.setState({ copied: false }); }, 100)
    };

    render() {

        const { allLocations, pageCount, pageSize, pageNumber, sortBy, order, locName, totalCount, isLibraryStepUpdated, copied } = this.state;
        const { loading } = this.props;
        const startCount = ((pageNumber ? pageNumber - 1 : 0) * pageSize) + 1;
        const endCount = ((pageNumber ? pageNumber - 1 : 0) + 1) * pageSize;
        return (
            <PageContainer>
                {loading && <Spinner />}
                <SubHeader>
                    <FilterContainer>
                        <InputContainer>
                            <StyledLabel>Location Name</StyledLabel>
                            <StyledInput
                                type="text"
                                value={locName}
                                onChange={(e) => this.handleFilterInputChange(e.target.value)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <StyledLabel>&ensp;</StyledLabel>
                            <ButtonContainer>
                                <Button variant='success' disabled={!locName.length} type='submit' onClick={this.getFilteredData} className='mx-1' >
                                    <Icon className='fa fa-search' />
                                </Button>
                                <Button variant='secondary' reset={true} onClick={this.resetFilter} className='mx-1' type='button'>
                                    <Icon className='fa fa-undo' />
                                </Button>
                                <Button variant='primary' onClick={this.downloadData} className='mx-1' type='button'>
                                    <img src={DownloadIcon} alt="" />
                                </Button>
                            </ButtonContainer>
                        </InputContainer>
                    </FilterContainer>
                    <PageCountContainer>
                        <p>{startCount}-{endCount > totalCount ? totalCount : endCount} of {totalCount}</p>
                    </PageCountContainer>
                    <PageSizeContainer>
                        <StyledLabel>&ensp;</StyledLabel>
                        <div>
                            <Select
                                styles={DropDownStyle}
                                defaultValue={pageSizeOptions.filter((val) => val.value === pageSize)}
                                value={pageSizeOptions.filter((val) => val.value === pageSize)}
                                options={pageSizeOptions}
                                onChange={(e) => this.handlePageSizeInputChanges(e.value)}
                            />
                        </div>
                    </PageSizeContainer>
                </SubHeader>
                <TableContainer>
                    <Table className="table  table-bordered">
                        <TableHead>
                            <TableRow>
                                <TableHeaderElement className='w-20' scope="col">Location Name
                                    {sortBy !== 'lowerLocationName' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lowerLocationName', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'lowerLocationName' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lowerLocationName', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'lowerLocationName' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lowerLocationName', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-20' scope="col">Practice Name
                                    {sortBy !== 'lowerPracticeName' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lowerPracticeName', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'lowerPracticeName' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lowerPracticeName', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'lowerPracticeName' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lowerPracticeName', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-15' scope="col">Location Type
                                    {sortBy !== 'type' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('type', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'type' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('type', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'type' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('type', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-15' scope="col">Subscription Type
                                    {
                                        sortBy !== 'locSubscriptionPlan' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('locSubscriptionPlan', 'asc')} className='bi bi-arrow-down-up text-dark' />
                                    }
                                    {
                                        order === 'asc' && sortBy === 'locSubscriptionPlan' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('locSubscriptionPlan', 'desc')} className='bi bi-sort-alpha-down text-success' />
                                    }
                                    {
                                        order === 'desc' && sortBy === 'locSubscriptionPlan' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('locSubscriptionPlan', 'asc')} className='bi bi-sort-alpha-up text-success' />
                                    }
                                </TableHeaderElement>
                                <TableHeaderElement className='w-15' scope="col">State
                                    {sortBy !== 'address.state' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('address.state', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'address.state' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('address.state', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'address.state' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('address.state', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-10' scope="col">City
                                    {sortBy !== 'address.city' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('address.city', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'address.city' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('address.city', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'address.city' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('address.city', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-10 text-center' scope="col">
                                    User Count
                                </TableHeaderElement>
                                <TableHeaderElement className='w-10 text-center' scope="col">
                                    Embedding
                                    {isLibraryStepUpdated === undefined && <Icon onClick={(e) => {
                                        this.setState({ isLibraryStepUpdated: false }, () => {
                                            this.getFilteredData(e)
                                        });
                                    }} className="bi bi-square text-secondary"></Icon>}
                                    {isLibraryStepUpdated === false && <Icon onClick={(e) => {
                                        this.setState({ isLibraryStepUpdated: true }, () => {
                                            this.getFilteredData(e)
                                        });
                                    }} className="bi bi-check-square text-success"></Icon>}
                                    {isLibraryStepUpdated === true && <Icon onClick={(e) => {
                                        this.setState({ isLibraryStepUpdated: undefined }, () => {
                                            this.getFilteredData(e)
                                        });
                                    }} className="bi bi-x-square text-danger"></Icon>}
                                </TableHeaderElement>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allLocations && allLocations.map((location) =>
                                <TableRow rowColor={!location.didCompleteLibrarySetup && '#ffff009c'} key={location._id}>
                                    <TableDataElement className='w-20'>
                                        {location.name}
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 10, hide: 10 }}
                                            overlay={
                                                <Tooltip id='tooltip-top'>
                                                    {copied ? <span>&#10003;  Copied</span> : <span>Copy ID</span>}
                                                </Tooltip>
                                            }
                                        >
                                            <span
                                                style={{ cursor: 'pointer', fontSize: '11px' }}
                                                onClick={() => this.copyLocationId(location._id)}
                                                onMouseLeave={this.handleMouseLeave}
                                                className='text-success'
                                            >
                                                {location._id}
                                            </span>
                                        </OverlayTrigger>
                                    </TableDataElement>
                                    <TableDataElement className='w-20'>
                                        {
                                            location.practice ?
                                                location.practice.name
                                                    ? location.practice.name
                                                    :
                                                    '--'
                                                :
                                                '--'
                                        }
                                        {
                                            location.practice && location.practice._id &&
                                            <ArrowButton onClick={(e) => this.goTo('/practice', location.practice._id)} />
                                        }
                                    </TableDataElement>
                                    <TableDataElement className='w-15'>
                                        <Capitalize >
                                            {location.type ? location.type : '--'}
                                        </Capitalize>
                                    </TableDataElement>
                                    <TableDataElement className='w-15'>
                                        <Capitalize >
                                            {
                                                location.locSubscriptionPlan ? location.locSubscriptionPlan : '--'}
                                        </Capitalize>
                                    </TableDataElement>
                                    <TableDataElement className='w-15'>
                                        {
                                            location.address.state ?
                                                states.find((state) => state.value === location.address.state).label
                                                : '--'
                                        }
                                    </TableDataElement>
                                    <TableDataElement className='w-10'>
                                        {
                                            location.address.city ?
                                                location.address.city
                                                :
                                                '--'
                                        }
                                    </TableDataElement>
                                    <TableDataElement className='w-10 text-center'>
                                        <ArrowButton onClick={(e) => this.goTo('/users', location._id)}>
                                            {location.users ? location.users.length : '0'}
                                        </ArrowButton>
                                    </TableDataElement>
                                    <TableDataElement className='w-10 text-center'>
                                        <button disabled={!location.isLibraryStepUpdated || location.embeddingStatus === 'In Progress'}
                                            className='btn btn-outline-success'
                                            onClick={(e) => this.startEmbeddingForLocation(e, location._id)} type="button">
                                            {location.embeddingStatus === 'In Progress' ?
                                                <div className="spinner-border spinner-border-sm" role="status">
                                                    <span className="sr-only">
                                                        Loading...
                                                    </span>
                                                </div>
                                                :
                                                <img height="20" width="20" src={Vector} alt="Start embedding." title="Start embedding." />
                                            }
                                        </button>
                                    </TableDataElement>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {
                        (!allLocations || !allLocations.length) &&
                        <NoDataFound>{loading ? 'Loading...' : 'No Data Found'}</NoDataFound>
                    }
                </TableContainer>
                <PaginationContainer>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next >"
                        onPageChange={this.handlePageClick}
                        pageRangeDisplayed={10}
                        pageCount={pageCount}
                        previousLabel="< Previous"
                        renderOnZeroPageCount={null}
                        forcePage={pageNumber !== 0 ? pageNumber - 1 : 0}
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item hide"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link nextAndPrev"
                        nextClassName="page-item"
                        nextLinkClassName="page-link nextAndPrev"
                        activeClassName="active"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                    />
                </PaginationContainer>
            </PageContainer>
        );
    };
};

const mapStateToProps = (state) => ({
    locations: state.locations.locations.data,
    locationsCount: state.locations.locations.count,
    loading: state.locations.loading
});

export default withRouter(connect(mapStateToProps)(Location));